import React, { useEffect, useState } from 'react'
import {
  Card,
  Form,
  Button,
  Space,
  Table,
  ConfigProvider,
  Breadcrumb,
  Input,
  Select,
  Radio,
  Drawer,
  message,
  Modal,
  Tabs,
  Tooltip,
  Checkbox,
} from 'antd'
import styles from './index.module.scss'
import zhCH from 'antd/lib/locale/zh_CN'
import { useDispatch, useSelector } from 'react-redux'
import {
  approveApplPermission,
  batchApproveApplPermission,
  editAutoApproveStatus,
  getApplPermissionList,
  getAutoApproveStatus,
  getPermissionList,
  getPermissionOrgList,
  getPermissionUserList,
  getTrigger,
} from '../../../store/actions/permission'
import { useRef } from 'react'
import { getUserList } from '../../../store/actions/user'
import { getAdmin, getCompany, getCreator } from '../../../utils/cookie'
import { MyIcon } from '../../../routers'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../components/MyButton'
import shanchu from '../../images/shanchu.png'
export default function MemberApplication() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [aggrVisibel, setAggreVisible] = useState(false)
  const [permissInfo, setPermissInfo, getPermissInfo] = useGetState({})
  const [coId, setCoId, getCoId] = useGetState('')
  const [roleIdList, setRoleIdList] = useState([])
  const [batchDel, setBatchDel] = useState(false)
  const [compId, setCompId] = useState(getCompany())
  const [roleId, setRoleId] = useState('')
  const addRef = useRef()
  const tableRef = useRef(null)
  const size = useSize(tableRef)

  const [form] = Form.useForm()
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])

  useEffect(() => {
    dispatch(
      getPermissionList({
        co_id: getCompany(),
      })
    )
    dispatch(
      getApplPermissionList({
        appl_status_code: 'SUBMIT',
      })
    )
    dispatch(getAutoApproveStatus())
  }, [])
  const {
    applPermissionList,
    applPermissionTotal,
    autoApproveStatus,
    permissionList,
  } = useSelector((state) => state.permission)
  const userInfo = useSelector((state) => state.login.userInfo)
  // 角色表格数据
  const columns = [
    {
      title: '姓名',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '申请加入部门',
      dataIndex: 'role_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '申请时间',
      dataIndex: 'created_time',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '加入理由',
      dataIndex: 'appl_reason',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          overlayStyle={{ maxWidth: 560 }}
          placement="leftTop"
          title={text}
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      key: 'permiss',
      width: 120,
      fixed: 'right',
      render(permiss) {
        return (
          <Space>
            <Button
              style={{ color: '#535353' }}
              type="link"
              onClick={async () => {
                setBatchId('')
                await setActionType('单选')
                showReject(permiss)
              }}
            >
              <Tooltip title="拒绝">
                <MyIcon type="icon-cross" style={{ fontSize: 14 }} />
              </Tooltip>
            </Button>
            <Button
              style={{ color: '#535353' }}
              type="link"
              onClick={async () => {
                setBatchId('')
                await setActionType('单选')
                showAgree(permiss)
              }}
            >
              <Tooltip title="同意">
                <MyIcon type="icon-tick" style={{ fontSize: 14 }} />{' '}
              </Tooltip>
            </Button>
          </Space>
        )
      },
    },
  ]
  // 批量删除
  const [batchId, setBatchId] = useState('')
  const [actionType, setActionType, getActionType] = useGetState('')
  console.log(batchId.length, actionType)
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setBatchId(selectedRowKeys)
    },
  }
  // 同意弹窗
  const showAgree = (per) => {
    if (batchId?.length === 0 && getActionType() === '批量') {
      return message.error('请至少勾选一条数据')
    } else if (getActionType() === '单选') {
      setPermissInfo(per)
      setAggreVisible(true)
    } else {
      setAggreVisible(true)
    }
  }
  // 拒绝弹窗
  const showReject = (per) => {
    if (batchId?.length === 0 && getActionType() === '批量') {
      return message.error('请至少勾选一条数据')
    } else if (getActionType() === '单选') {
      setPermissInfo(per)
      setVisible(true)
    } else {
      setVisible(true)
    }
  }
  //拒绝申请和批量拒绝申请
  const rejectAppl = async (id) => {
    setVisible(false)
    try {
      if (actionType === '单选') {
        const res = await dispatch(
          batchApproveApplPermission([
            {
              appl_id: id,
              appl_status_code: 'REJECT',
            },
          ])
        )
      } else {
        const arr = batchId.flatMap((user) => {
          console.log(user)
          return {
            appl_id: user,
            appl_status_code: 'REJECT',
          }
        })
        console.log(arr)
        const res = await dispatch(batchApproveApplPermission(arr))
      }
      await dispatch(
        getApplPermissionList({
          appl_status_code: 'SUBMIT',
        })
      )
    } catch (error) {
      return console.log(error)
    }
  }

  const aggreAppl = async (id) => {
    setAggreVisible(false)
    try {
      if (actionType === '单选') {
        const res = await dispatch(
          batchApproveApplPermission([
            {
              appl_id: id,
              appl_status_code: 'APPROVE',
              org_id: '',
              role_id: roleIdList,
            },
          ])
        )
      } else {
        const arr = batchId.flatMap((user) => {
          console.log(user)
          return {
            appl_id: user,
            appl_status_code: 'APPROVE',
            org_id: '',
            role_id: roleIdList,
          }
        })
        console.log(arr)
        const res = await dispatch(batchApproveApplPermission(arr))
      }
      await dispatch(
        getApplPermissionList({
          appl_status_code: 'SUBMIT',
        })
      )
    } catch (error) {
      return console.log(error)
    }
  }

  // 搜索
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    current: paramsRef.current.page,
    total: applPermissionTotal,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (applPermissionTotal) => {
      return `共${applPermissionTotal}条`
    },
  }
  const onSearch = async (values) => {
    setRoleId(values?.co_id)
    paramsRef.current.co_id = values.co_id
    paramsRef.current.fuzzy_name = values.fuzzy_name
    paramsRef.current.page = 1
    await dispatch(getPermissionList(paramsRef.current))
  }

  // 角色分页处理
  const onChange = (page, pageSize) => {
    paramsRef.current.co_id = roleId
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    dispatch(getPermissionList(paramsRef.current))
  }

  //自动同意申请checkbox
  const onAgree = async (e) => {
    console.log(`checked = ${e.target.checked}`)
    await dispatch(
      editAutoApproveStatus({ is_auto_approve: e.target.checked }, false)
    )
    await dispatch(getAutoApproveStatus(false))
  }
  console.log(autoApproveStatus)
  const [roleList, setRoleList] = useState([])
  useEffect(() => {
    if (getAdmin() && !getCreator()) {
      setRoleList(
        permissionList?.filter((item) => !item.is_creator && !item.is_admin)
      )
    } else if (getCreator()) {
      setRoleList(permissionList?.filter((item) => !item.is_creator))
    }
  }, [permissionList])

  return (
    <ConfigProvider locale={zhCH}>
      <div className={styles.root}>
        <div className="right_content">
          <Breadcrumb>
            <Breadcrumb.Item>企业管理</Breadcrumb.Item>
            <Breadcrumb.Item>新成员申请</Breadcrumb.Item>
          </Breadcrumb>
          <Card className="search-card">
            <Form
              autoComplete="off"
              form={form}
              className="form-search"
              onFinish={onSearch}
            >
              {/* 'NORMAL' 'SUSPEND' */}

              <div className="co_text">
                {userInfo.co_name}
                {/* <MyIcon
                  type="icon-a-lujing1"
                  style={{ fontSize: 14, marginLeft: 8 }}
                /> */}
              </div>
              <Space size={[24]} className="space-heigth">
                <Checkbox
                  checked={autoApproveStatus}
                  defaultChecked={autoApproveStatus}
                  onChange={onAgree}
                >
                  <span style={{ color: '#0068b2' }}>自动同意申请</span>
                </Checkbox>
                <MyButton
                  width={110}
                  type="default"
                  onClick={async () => {
                    await setActionType('批量')
                    showAgree()
                  }}
                >
                  <MyIcon type="icon-a-lujing11" style={{ fontSize: 14 }} />
                  批量同意
                </MyButton>
                <MyButton
                  className="btn_style"
                  bgcolor="#EFEFEF"
                  color="#535353"
                  width={110}
                  onClick={async () => {
                    await setActionType('批量')
                    showReject()
                  }}
                >
                  <MyIcon type="icon-a-lujing2" style={{ fontSize: 14 }} />
                  批量拒绝
                </MyButton>
              </Space>
            </Form>
          </Card>

          <div className="right_table" ref={tableRef}>
            <Table
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey="appl_id"
              rowSelection={{
                ...rowSelection,
                getCheckboxProps(value) {
                  return {
                    disabled: value.is_preset,
                  }
                },
              }}
              columns={columns}
              dataSource={applPermissionList}
              pagination={pagination}
            ></Table>
          </div>
        </div>
      </div>
      <Modal
        title="拒绝新成员申请"
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setVisible(false)}>取消</MyButton>
            <MyButton
              type="default"
              bgcolor="#FE545F"
              onClick={() => {
                rejectAppl(permissInfo.appl_id)
              }}
            >
              拒绝
            </MyButton>
          </Space>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <img style={{ marginBottom: 24 }} src={shanchu} alt="" />
          <p>确定要拒绝新成员{permissInfo.full_name}的申请吗？</p>
        </div>
      </Modal>
      <Modal
        title="同意新成员申请"
        visible={aggrVisibel}
        onCancel={() => setAggreVisible(false)}
        centered
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setAggreVisible(false)}>取消</MyButton>
            <MyButton
              type="default"
              onClick={() => aggreAppl(permissInfo.appl_id)}
            >
              同意
            </MyButton>
          </Space>,
        ]}
      >
        <Form
          colon={false}
          layout="vertical"
          onFinish={(values) => {
            console.log(values)
          }}
        >
          {/* <Form.Item label="选择加入部门" required>
            <Select></Select>
          </Form.Item> */}
          <Form.Item label="默认角色">
            <Select
              onChange={(value, option) => {
                setRoleIdList(value)
              }}
              mode="multiple"
              defaultActiveFirstOption={false}
              style={{ width: '100%' }}
              placeholder="请选择角色"
            >
              {roleList.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.role_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Card,
  Form,
  Button,
  Space,
  Table,
  ConfigProvider,
  Breadcrumb,
  Input,
  Select,
  TreeSelect,
  Drawer,
  message,
  Modal,
  Tabs,
  Tooltip,
  Dropdown,
} from 'antd'
import styles from './index.module.scss'
import zhCH from 'antd/lib/locale/zh_CN'
import { useDispatch, useSelector } from 'react-redux'
import {
  batchAddUser,
  batchDelUser,
  editPermission,
  getPermissionList,
  getPermissionOrgList,
  getPermissionUserList,
  getTrigger,
  handover,
  invite,
  removeMembers,
} from '../../../store/actions/permission'
import { useRef } from 'react'
import { batchDel, delUsers, getUserList } from '../../../store/actions/user'
import { getAdmin, getCompany, getCreator } from '../../../utils/cookie'
import { MyIcon } from '../../../routers'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../components/MyButton'
import {
  changeOrgs,
  getOrgAllList,
  getOrgTreeList,
} from '../../../store/actions/org'
import { useHistory } from 'react-router-dom'
import { getUserInfo } from '../../../store/actions/login'
// import qrcode from '../../images/qiyewx.png'
export default function MemberManagement() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [permissInfo, setPermissInfo, getPermissInfo] = useGetState({})
  const [coId, setCoId, getCoId] = useGetState('')
  const [qrcode, setQrcode] = useState(null)
  const [inviteUrl, setInviteUrl] = useState(null)
  const [changeOrgModal, setChangeOrgModal] = useState(false)
  const [delModal, setDelModal] = useState(false)
  const [compId, setCompId] = useState(getCompany())
  const [roleId, setRoleId] = useState('')
  const [btnShow, setBtnShow] = useState(false)
  const [showSelect, setShowSelect] = useState(false)
  const [memberInfo, setMemberInfo] = useState({})
  const [user, setUser] = useState({})
  const [moveValue, setMoveValue] = useState(null)
  const [orgId, setOrgId] = useState('')
  const [transferModal, setTransferModal] = useState(false)
  const [transferSecondModal, setTransferSecondModal] = useState(false)
  const [roleModal, setRoleModal] = useState(false)
  const [roleIdList, setRoleIdList] = useState([])
  const [batchId, setBatchId] = useState('')
  const userInfo = useSelector((state) => state.login.userInfo)
  const saveRef = useRef()
  const tableRef = useRef(null)
  const size = useSize(tableRef)
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })

  const [form] = Form.useForm()
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])

  useEffect(() => {
    dispatch(
      getUserList({
        co_id: getCompany(),
      })
    )
    dispatch(
      getPermissionList({
        co_id: getCompany(),
      })
    )
    dispatch(
      getOrgTreeList({
        co_id: getCompany(),
      })
    )
  }, [dispatch])

  const { total, userList } = useSelector((state) => state.user)
  const { permissionList } = useSelector((state) => state.permission)
  const { orgTreeList } = useSelector((state) => state.org)
  const handleChange = (value) => {
    console.log(`selected ${value}`)
  }
  const handleShowSelect = (info) => {
    console.log(info)
  }
  const [roleList, setRoleList] = useState([])
  useEffect(() => {
    if (getAdmin() && !getCreator()) {
      setRoleList(
        permissionList?.filter((item) => !item.is_creator && !item.is_admin)
      )
    } else if (getCreator()) {
      setRoleList(permissionList?.filter((item) => !item.is_creator))
    }
  }, [permissionList])

  // 角色表格数据
  const columns = [
    {
      title: '成员',
      dataIndex: 'user',
      ellipsis: {
        showTitle: false,
      },
      render: (i, text, o) => {
        // console.log(i, text, o)
        return (
          <Tooltip
            placement="rightBottom"
            title={
              <div
                style={{
                  fontSize: '14px',
                  padding: '2px',
                  width: '300px',
                }}
              >
                <div style={{ marginBottom: 16 }}>
                  <img
                    style={{ borderRadius: '50%', marginRight: 16 }}
                    height={35}
                    width={35}
                    src={text.pf_photo}
                    alt=""
                  />
                  <span>{text.user_name || '-'}</span>
                </div>
                <div>
                  <span className="member_tips_text">角色</span>
                  <span>{i.role_name || '-'}</span>
                </div>
                <div>
                  <span className="member_tips_text">昵称</span>
                  <span>{text.nickname || '-'}</span>
                </div>
                <div>
                  <span className="member_tips_text">姓名</span>
                  <span>{text.full_name || '-'}</span>
                </div>
                <div>
                  <span className="member_tips_text">手机号</span>
                  <span>{i.mobile_no || '-'}</span>
                </div>
                <div>
                  <span className="member_tips_text">邮箱</span>
                  <span>{text.email || '-'}</span>
                </div>
                <div>
                  <span className="member_tips_text">加入时间</span>
                  <span>{text.created_time || '-'}</span>
                </div>
              </div>
            }
          >
            <img
              style={{ borderRadius: '50%', marginRight: 6 }}
              height={24}
              width={24}
              src={text.pf_photo}
              alt=""
            />
            <span>{text.user_name}</span>
          </Tooltip>
        )
      },
    },
    {
      title: '姓名',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '角色',
      dataIndex: 'roles',
      ellipsis: {
        showTitle: false,
      },
      render: (i, text, o) => {
        const t = text.user
        return (
          <Tooltip placement="leftTop" title={t.role_name}>
            {t.role_name}
          </Tooltip>
        )
      },
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      fixed: 'right',
      render(r, c, i) {
        const isAdmin = r.is_admin //是否管理员
        const isCreator = r.is_creator //是否创建者
        if (getCreator() && !isCreator) {
          return (
            <Space>
              <Button
                style={{ position: 'relative' }}
                className="edit"
                type="link"
                onClick={async () => {
                  setRoleIdList(c?.user?.roles)
                  setBatchId('')
                  await setActionType('单选')
                  showRoleModal(c)
                }}
              >
                <Tooltip title="设置角色">
                  <MyIcon type="icon-a-lujing111" style={{ fontSize: 14 }} />
                </Tooltip>
              </Button>
              <Button
                type="link"
                className="edit"
                onClick={async () => {
                  setBatchId('')
                  await setActionType('单选')
                  showDelModal(c)
                }}
              >
                <Tooltip title="移除成员">
                  <MyIcon type="icon-a-lujing12" style={{ fontSize: 14 }} />
                </Tooltip>
              </Button>
            </Space>
          )
        }
        if (getAdmin() && !isCreator && !isAdmin) {
          return (
            <Space>
              <Button
                style={{ position: 'relative' }}
                className="edit"
                type="link"
                onClick={async () => {
                  setRoleIdList(c?.user?.roles)
                  setBatchId('')
                  await setActionType('单选')
                  showRoleModal(c)
                }}
              >
                <Tooltip title="设置角色">
                  <MyIcon type="icon-a-lujing111" style={{ fontSize: 14 }} />
                </Tooltip>
              </Button>
              <Button
                type="link"
                className="edit"
                onClick={async () => {
                  setBatchId('')
                  await setActionType('单选')
                  showDelModal(c)
                }}
              >
                <Tooltip title="移除成员">
                  <MyIcon type="icon-a-lujing12" style={{ fontSize: 14 }} />
                </Tooltip>
              </Button>
            </Space>
          )
        }
      },
    },
  ]
  const paginations = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    current: paramsRef.current.page,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }
  const [actionType, setActionType, getActionType] = useGetState('')
  //移除弹窗
  const showDelModal = (c) => {
    console.log(c, getActionType(), batchId)
    if (batchId?.length === 0 && getActionType() === '批量') {
      return message.error('请至少勾选一条数据')
    } else if (getActionType() === '单选') {
      setMemberInfo(c)
      setUser(c.user)
      setDelModal(true)
    } else {
      setDelModal(true)
    }
  }

  //移除
  const delUser = async (id) => {
    const res = await dispatch(
      batchId !== ''
        ? batchDel({
            co_user_id__in: batchId.join(','),
          })
        : delUsers(id)
    )
    console.log(res)
    // if (res.data.code === 0) {
    //   message.success('移除成功')
    // } else {
    //   message.error(res.data.msg)
    // }
    setDelModal(false)
    await dispatch(
      getUserList({
        co_id: getCompany(),
      })
    )
  }

  // 批量移除
  const [userIdList, setUserIdList] = useState('')
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setUserIdList(
        selectedRows
          ?.map((item) => {
            return item?.user?.id
          })
          .join(',')
      )
      setBatchId(selectedRowKeys)
    },
  }
  //变更部门弹窗
  const showChangeOrgModal = (c) => {
    console.log(c, getActionType())
    if (batchId?.length === 0 && getActionType() === '批量') {
      return message.error('请至少勾选一条数据')
    } else if (getActionType() === '单选') {
      setUser(c.user)
      setMemberInfo(c)
      setChangeOrgModal(true)
    } else {
      setChangeOrgModal(true)
    }
  }
  // 邀请成员
  const showAdd = async () => {
    let values = {}
    values.org_id = ''
    const res = await dispatch(invite(values, false))
    console.log(res.data)
    await setQrcode(res.data.data.qrcode)
    await setInviteUrl(res.data.data.url)
    if (res.data.code === 0) {
      setVisible(true)
    }
  }

  // 搜索
  const onSearch = async (values) => {
    setRoleId(values?.co_id)
    paramsRef.current.co_id = compId
    paramsRef.current.fuzzy_name = values.fuzzy_name
    paramsRef.current.page = 1
    await dispatch(getUserList(paramsRef.current))
  }

  // 角色分页处理
  const onChange = async (page, pageSize) => {
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    await dispatch(getUserList(paramsRef.current))
  }

  const onSetRoleId = () => {
    if (!batchId) return message.error('请至少勾选一条数据')
  }
  // 选择部门
  const changeOrg = (value, node) => {
    setOrgId(node.org_id)
    setMoveValue(value)
  }

  const changeMove = async (params) => {
    console.log(moveValue, params)
    if (!moveValue) {
      return message.error('请选择部门')
    }
    await dispatch(
      changeOrgs({
        co_user_id: batchId !== '' ? batchId : memberInfo.co_user_id,
        org_id: orgId,
      })
    )
    setChangeOrgModal(false)
  }
  const [transId, setTransId] = useState('')
  //移交创建者权限
  const TransFerModalShow = () => {
    setTransferModal(true)
  }
  const transferFn = async () => {
    let values = {}
    values.co_user_id = transId
    const res = await dispatch(handover(values))
    if (res.data.code === 0) {
      dispatch(getUserInfo())
      message.success('移交权限成功')
      history.push('/saas/personalsettings')
    } else {
      message.error(res.data.msg)
    }
    setTransId('')
    setTransferModal(false)
    setTransferSecondModal(false)
    // dispatch(
    //   getUserList({
    //     co_id: getCompany(),
    //   })
    // )
  }
  const showRoleModal = (c) => {
    if (batchId?.length === 0 && getActionType() === '批量') {
      return message.error('请至少勾选一条数据')
    } else if (getActionType() === '单选') {
      setMemberInfo(c)
      setUser(c.user)
      setRoleModal(true)
    } else {
      setRoleModal(true)
    }
  }
  const setRolesFn = async () => {
    console.log(roleIdList, batchId, memberInfo, actionType, user)
    if (batchId?.length > 1) {
      await dispatch(batchDelUser({ user__in: userIdList }))
    } else {
      await dispatch(batchDelUser({ user: user.id }))
    }
    try {
      if (actionType === '单选') {
        const userId = [user.id]
        const arr = userId.flatMap((user) =>
          roleIdList.map((role) => ({ role, user }))
        )
        // 再添加
        await dispatch(batchAddUser(arr))
      } else {
        //多选
        const arr = userIdList
          ?.split(',')
          ?.flatMap((user) => roleIdList.map((role) => ({ role, user })))
        console.log(arr)
        await dispatch(batchAddUser(arr))
      }
      setRoleModal(false)
      dispatch(
        getUserList({
          co_id: getCompany(),
        })
      )
    } catch (error) {
      setRoleModal(false)
    }
  }
  return (
    <ConfigProvider locale={zhCH}>
      <div className={styles.root}>
        <div className="right_content">
          <Breadcrumb>
            <Breadcrumb.Item>企业管理</Breadcrumb.Item>
            <Breadcrumb.Item>成员管理</Breadcrumb.Item>
          </Breadcrumb>
          <Card className="search-card">
            <div className="co_text">
              {userInfo.co_name}
              {/* <MyIcon
                type="icon-a-lujing1"
                style={{ fontSize: 14, marginLeft: 8 }}
              /> */}
            </div>
            <Form
              autoComplete="off"
              form={form}
              className="form-search"
              onFinish={onSearch}
            >
              {/* 'NORMAL' 'SUSPEND' */}

              <div className="flex">
                <Form.Item name="fuzzy_name">
                  <Input
                    onBlur={(e) => {
                      onSearch({
                        fuzzy_name: e.target.value,
                      })
                    }}
                    onPressEnter={() => {}}
                    placeholder="查找您想要的内容"
                    style={{ width: 238 }}
                    prefix={
                      <MyIcon
                        className="icon"
                        type="icon-sousuo1"
                        style={{ fontSize: 14 }}
                      />
                    }
                  ></Input>
                </Form.Item>
              </div>
              <Space size={[24]} className="space-heigth">
                <MyButton type="default" onClick={showAdd} width={132}>
                  <MyIcon type="icon-chuangjian" style={{ fontSize: 14 }} />
                  邀请成员加入
                </MyButton>
                {/* <MyButton
                  className="btn_style"
                  bgcolor="#EFEFEF"
                  color="#535353"
                  width={110}
                >
                  <MyIcon type="icon-a-lujing3" style={{ fontSize: 14 }} />
                  新增部门
                </MyButton> */}
                <MyButton
                  onClick={() => {
                    setBtnShow(!btnShow)
                  }}
                  style={{ position: 'relative' }}
                  className="btn_style"
                  bgcolor="#EFEFEF"
                  color="#535353"
                  width={130}
                >
                  <MyIcon type="icon-a-lujing4" style={{ fontSize: 14 }} />
                  成员批量操作
                  {btnShow && (
                    <div className="btn_list">
                      <a
                        style={{ width: '100%', textAlign: 'left' }}
                        onClick={async () => {
                          await setActionType('批量')
                          showRoleModal()
                        }}
                      >
                        角色
                      </a>
                      {/* <a
                        onClick={async () => {
                          await setActionType('批量')
                          showChangeOrgModal()
                        }}
                      >
                        变更部门
                      </a> */}
                      <a
                        style={{ width: '100%', textAlign: 'left' }}
                        onClick={async () => {
                          await setActionType('批量')
                          showDelModal()
                        }}
                      >
                        移除
                      </a>
                    </div>
                  )}
                </MyButton>
                {getCreator() && (
                  <MyButton
                    className="btn_style"
                    bgcolor="#EFEFEF"
                    color="#535353"
                    width={150}
                    onClick={() => {
                      TransFerModalShow()
                    }}
                  >
                    <MyIcon type="icon-a-zhuanyi2" style={{ fontSize: 14 }} />
                    移交创建者权限
                  </MyButton>
                )}
              </Space>
            </Form>
          </Card>

          <div className="right_table" ref={tableRef}>
            <Table
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey="co_user_id"
              rowSelection={{
                ...rowSelection,
                getCheckboxProps(value) {
                  return {
                    disabled: value.is_creator,
                  }
                },
              }}
              columns={columns}
              dataSource={userList}
              pagination={paginations}
            ></Table>
          </div>
        </div>
      </div>
      {/* 移除成员 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelModal(false)}
        title={getActionType() === '单选' ? '移除成员' : '批量移除成员'}
        visible={delModal}
        getContainer={false}
        okText="删除"
        cancelText="取消"
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelModal(false)}>取消</MyButton>
            <MyButton
              type="default"
              onClick={() => delUser(memberInfo.co_user_id)}
            >
              移除
            </MyButton>
          </Space>,
        ]}
      >
        {getActionType() === '单选' ? (
          <p>确定要移除成员"{memberInfo.user_name}"吗？</p>
        ) : (
          <p>确定要移除所选成员吗？</p>
        )}
      </Modal>
      {/* 变更部门 */}
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={getActionType() === '单选' ? '变更部门' : '批量变更部门'}
        visible={changeOrgModal}
        onCancel={() => setChangeOrgModal(false)}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setChangeOrgModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => changeMove(memberInfo)}>
              修改
            </MyButton>
          </Space>,
        ]}
      >
        <div>
          <span style={{ color: 'red' }}>* </span>部门
        </div>
        <TreeSelect
          style={{ width: '100%' }}
          treeDefaultExpandAll
          key="org_id"
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={orgTreeList}
          placeholder="请选择部门"
          onSelect={changeOrg}
        ></TreeSelect>
      </Modal>
      <Modal
        title="移交创建者权限"
        visible={transferModal}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={() => {
          setTransId('')
          setTransferModal(false)
        }}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton
              onClick={() => {
                setTransId('')
                setTransferModal(false)
              }}
            >
              取消
            </MyButton>
            <MyButton
              type="default"
              onClick={() => {
                if (transId === '') {
                  return message.info('请选择要移交权限的成员')
                }
                setTransferSecondModal(true)
              }}
            >
              移交
            </MyButton>
          </Space>,
        ]}
      >
        <div>移交给</div>
        <Select
          placeholder="请选择用户"
          style={{ width: '100%' }}
          onChange={(value, option) => {
            setTransId(value)
          }}
        >
          {userList
            ?.filter((_) => !_.is_creator)
            .map((item) => (
              <Select.Option value={item.co_user_id} key={item.co_user_id}>
                {item.user_name}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            color: '#FE545F',
            fontWeight: 400,
            fontSize: '12px',
            marginTop: 8,
            // marginBottom: 16,
          }}
        >
          创建者权限包括：设置管理员、解散企业/组织等权限
        </div>
      </Modal>
      <Modal
        title="移交创建者权限"
        visible={transferSecondModal}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={() => setTransferSecondModal(false)}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setTransferSecondModal(false)}>
              取消
            </MyButton>
            <MyButton
              bgcolor="#FE545F"
              type="default"
              onClick={() => {
                transferFn()
              }}
            >
              移交
            </MyButton>
          </Space>,
        ]}
      >
        <div style={{ color: '#535353', textAlign: 'center', marginBottom: 8 }}>
          确定要移交创建者权限吗？
        </div>
        <div style={{ color: '#9E9E9E', textAlign: 'center' }}>
          移交后您将不再拥有设置管理员、解散企业/组织等权限
        </div>
      </Modal>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title="设置角色"
        visible={roleModal}
        onCancel={() => {
          setRoleIdList([])
          setRoleModal(false)
        }}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton
              onClick={() => {
                setRoleIdList([])
                setRoleModal(false)
              }}
            >
              取消
            </MyButton>
            <MyButton type="default" onClick={() => setRolesFn()}>
              确认
            </MyButton>
          </Space>,
        ]}
      >
        <div>角色</div>
        <Select
          onChange={(value, option) => {
            setRoleIdList(value)
          }}
          mode="multiple"
          defaultActiveFirstOption={false}
          style={{ width: '100%' }}
          defaultValue={user.roles}
          placeholder="请选择角色"
        >
          {roleList?.map((item) => (
            <Select.Option value={item.value} key={item.value}>
              {item.role_name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
      <Modal
        centered
        className="invite_modal"
        style={{ width: 220, height: 222 }}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => {
          setVisible(false)
        }}
        visible={visible}
        footer={[
          <Space size={[24]} className="model-btn-invite" direction="vertical">
            <MyButton
              width={124}
              onClick={() => {
                const imgElement = saveRef.current
                const downloadLink = document.createElement('a')

                downloadLink.href = imgElement.src
                downloadLink.download = 'qrcode.png' // 设置下载的文件名
                downloadLink.click()
              }}
            >
              保存二维码
            </MyButton>
            <MyButton
              width={124}
              type="default"
              onClick={() => {
                if (inviteUrl) {
                  navigator.clipboard.writeText(inviteUrl).then(() => {
                    message.success('文本已成功复制到剪贴板')
                  })
                }
              }}
            >
              复制邀请链接
            </MyButton>
          </Space>,
        ]}
      >
        <div
          style={{
            color: '#313131',
            fontWeight: 400,
            textAlign: 'center',
            paddingTop: 24,
          }}
        >
          <div style={{ marginBottom: 12 }}>邀请同事加入企业</div>
          {qrcode && (
            <img ref={saveRef} width={160} height={160} src={qrcode} alt="" />
          )}
        </div>
      </Modal>
    </ConfigProvider>
  )
}

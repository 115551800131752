import React from "react";
import styles from "./index.module.scss";
import api from "../../api";
import { useEffect, useRef, useState } from "react";
import { message, Drawer, Input, Popover, Avatar } from "antd";
import img from "../../assets/noProject.png";
import img2 from "../../assets/noProject2.png";
import {
  getAdmin,
  setToken,
  setSuperGroup,
  setUserStatus,
} from "../../utils/cookie";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../../components/MyButton";
import { CloseOutlined } from "@ant-design/icons";
import { useGetState } from "ahooks";
import { useHistory } from "react-router";
import { getMenuOfPersonal, getUserInfo } from "../../store/actions/login";
import { judgingStatus } from "../../utils/judgingStatus";
import useUrlState from "@ahooksjs/use-url-state";

export default function NoProject() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [admins, setAdmins] = useState([]);
  const userInfo = useSelector((state) => state.login.userInfo);
  const [open, setOpen] = useState(false);
  const [enterpriseName, setEnterpriseName, getEnterpriseName] =
    useGetState("");
  const [bindSuccess, setBindSuccess, getBindSuccess] = useGetState(false);
  const history = useHistory();
  const [state, setState] = useUrlState({});
  const [licenseList, setLicenseList, getLicenseList] = useGetState([]);
  const [project, setProject, getProject] = useGetState(null);

  const getSubsPlan = () => {
    api.getSubsPlan().then((res) => {
      console.log("getSubsPlan", res);
      if (res.data?.code === 0) {
        setLicenseList(res.data.data || []);
        if ((res.data?.data || [])?.length === 0) {
          //没有订阅过不处理
        } else {
          //先判断是哪个产品跳过来的
          if(state.project){
            let item = null
            //找到对应的产品的 订阅方案
            item = res.data?.data?.find(
              (_) => _?.prd_code === state.project
            );
            //若有跳到对应的产品
            if (item) {
              // console.log(item);
              window.location.href = item?.product_url;
            } 
          }else{
            //没有任何产品信息 跳到首页
              history.push("/home");
              dispatch(getUserInfo());
          }
        }
      } else {
        message.error(res.data.msg);
        setLicenseList([]);
      }
    });
  };

  const getProducts = () => {
    api.getProducts().then((res) => {
      console.log(res);
      if (res.data?.code === 0) {
        setProducts(res.data.data || []);
      } else {
        message.error(res.data.msg);
        setProducts([]);
      }
    });
  };

  const getAdminFunc = () => {
    api.getAdmin().then((res) => {
      console.log(res);
      if (res.data?.code === 0) {
        setAdmins(res.data.data);
      } else {
        message.error(res.data.msg);
        setAdmins([]);
      }
    });
  };

  useEffect(() => {
    document.title = "无项目";
    const project = state.project;
    setProject(project);
    getSubsPlan();
    getProducts();
    getAdminFunc();
  }, []);

  const initMsgDom = () => {
    const admin = getAdmin();
    const status = judgingStatus(userInfo.status);
    let title = "",
      text = "",
      spanText = "";
    if (getBindSuccess()) {
      title = "恭喜您创建组织/企业成功！";
      text = "您现在可以开通服务啦！";
      spanText = "（您也可以稍后在吾道产品介绍页面开通服务）";
    } else if (status === 1) {
      //未开通任何服务
      title = "您的企业/组织还未开通产品服务！";
      if (admin) {
        text = "建议您现在开通产品服务！";
        spanText = "（您也可以稍后在吾道产品介绍页面开通服务）";
      } else {
        text = (
          <>
            建议您联系
            <Popover
              placement="bottomLeft"
              title={null}
              // visible={true}
              overlayClassName="adminPopover"
              content={
                <div className="adminPopoverContent">
                  {admins?.map((item) => (
                    <div className="adminPopoverItem">
                      <span>
                        <Avatar
                          style={{ marginRight: "8px" }}
                          size={24}
                          src={item?.pf_photo}
                        />
                        {item?.show_name}
                      </span>
                      <span>{item?.assist_name}</span>
                    </div>
                  ))}
                </div>
              }
            >
              <a className="adminBox">管理员</a>
            </Popover>
            开通产品服务！
          </>
        );
        spanText = "（您可以先了解一下我们的产品服务）";
      }
    } else if (status === 2) {
      //企业审核中
      title = "您提交的加入企业/组织申请正在审核中！";
      text = "建议您完善个人信息，以方便使用吾道产品。";
      spanText = "（您也可以稍后在个人设置中完善和修改个人信息）";
    } else if (status === 3) {
      //未创建或加入企业
      title = "您还未创建或加入企业/组织！";
      text = "您可以现在创建企业或组织，并邀请同事加入！";
    } else if (getProject()) {
      title = "您的企业/组织还未开通此产品服务！";
      text = (
        <>
          建议您联系
          <Popover
            placement="bottomLeft"
            title={null}
            // visible={true}
            overlayClassName="adminPopover"
            content={
              <div className="adminPopoverContent">
                {admins?.map((item) => (
                  <div className="adminPopoverItem">
                    <span>
                      <Avatar
                        style={{ marginRight: "8px" }}
                        size={24}
                        src={item?.pf_photo}
                      />
                      {item?.show_name}
                    </span>
                    <span>{item?.assist_name}</span>
                  </div>
                ))}
              </div>
            }
          >
            <a className="adminBox">管理员</a>
          </Popover>
          开通产品服务！
        </>
      );
      spanText = "（您可以先了解一下我们的产品服务）";
    }

    return (
      <>
        <div className="userMsgTitle">{title}</div>
        {!getBindSuccess() && status === 2 && (
          <div className="userMsgText">您可以先了解一下我们的产品服务！</div>
        )}
        <div className="userMsgText">
          {text}
          <span>{spanText}</span>
          {status === 3 && (
            <MyButton
              bgcolor="#16D8D8"
              type="112312"
              onClick={() => {
                setEnterpriseName("");
                setTimeout(() => {
                  setOpen(true);
                }, 100);
              }}
            >
              创建企业
            </MyButton>
          )}
          {status === 2 && (
            <a href="/saas/personalsettings" target="_break">
              点击完善个人信息
            </a>
          )}
        </div>
        {getBindSuccess() && (
          <div className="userMsgText">
            开通服务后您可以邀请同事加入企业啦!
            <span>（您也可以稍后在企业管理中邀请同事加入）</span>
            <a href="/saas/businessmanagement/membermanagement" target="_break">
              点击邀请成员
            </a>
          </div>
        )}
        {getBindSuccess() && (
          <div className="userMsgText">
            建议您完善个人信息，以方便使用吾道产品。
            <span>（您也可以稍后在个人设置中完善和修改个人信息）</span>
            <a href="/saas/personalsettings" target="_break">
              点击完善个人信息
            </a>
          </div>
        )}
        {!getBindSuccess() && status === 3 && (
          <div className="userMsgText">
            如果您的企业或组织已经创建，可以联系企业管理员邀请您加入企业!
          </div>
        )}
      </>
    );
  };

  const bindEnterprise = () => {
    if (!getEnterpriseName()) {
      message.error("请输入组织或企业名称");
      return;
    }
    api.bindEnterprise(enterpriseName).then((res) => {
      console.log(res);
      if (res.data.code === 0) {
        api.switchEnterprise("", res.data.data?.company_id).then((res2) => {
          console.log(
            "🚀 ~ file: register.jsx:63 ~ Api.用户切换企业 ~ res2:",
            res2
          );
          if (res2.data.code === 0) {
            setToken(res2.data.data?.token || "");
            setSuperGroup(res2.data.data?.super_group || false);
            setUserStatus(1);
            dispatch(getUserInfo());
            dispatch(getMenuOfPersonal());
            setBindSuccess(true);
            setOpen(false);
          } else {
            message.error(res2.data.meg);
          }
        });
      } else {
        message.error(res.data.msg);
      }
    });
  };

  return (
    <div className={styles["root"]}>
      <div className="content">
        <div>
          <div className="userMsgBox">
            <img src={getBindSuccess() ? img2 : img} alt="" />
            <div className="userMsg">{initMsgDom()}</div>
          </div>

          {products?.length > 0 && <div className="productTitle">为您推荐</div>}
          {products?.length > 0 && (
            <div className="productListBox">
              {products?.map?.((item) => (
                <div className="productCard">
                  <img key={item.icon} src={item.icon} alt="" />
                  <div
                    className="productCardBtn"
                    onClick={() => {
                      window.open(item.product_home, "_block");
                    }}
                  >
                    查看详情
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Drawer
        destroyOnClose={true}
        closable={false}
        width={500}
        className="addEnterpriseDrawer"
        placement="right"
        title="新建企业或组织"
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        }
        onClose={() => {
          setOpen(false);
        }}
        visible={open}
        footer={
          <div className="drawerFooterBtnBox">
            <MyButton
              type="default"
              className=" ant-spaces"
              ml="10px"
              onClick={() => bindEnterprise()}
            >
              新建
            </MyButton>
            <MyButton className=" ant-spaces" onClick={() => setOpen(false)}>
              取消
            </MyButton>
          </div>
        }
      >
        <div className="labelDIv">
          <span>*</span>组织或企业名称
        </div>
        <Input
          value={enterpriseName}
          placeholder="输入组织或企业名称"
          onChange={(e) => {
            setEnterpriseName(e.target.value);
          }}
        ></Input>
      </Drawer>
    </div>
  );
}

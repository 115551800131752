import request from '../../utils/request'
import qs from 'qs'
import { GETMENULIST, GETMENUTREELIST } from '../constants'

/**
 * 获取树形数据
 * @returns
 */
export const getMenuTreeList = (params, disable) => {
  console.log(disable)
  return async (dispatch) => {
    const res = await request.get('sys/menu/tree/', {
      params,
    })
    const init = (arr) => {
      arr = arr.map((item) => {
        if (item.children) {
          item.children = init(item.children)
        }
        item.title = item.menu_name || '全部'
        item.value = item.menu_code || '全部'
        item.key = item.menu_id || '全部'
        item.disabled = disable && item.is_preset ? true : false
        return item
      })
      return arr
    }
    let list = [res.data.data]
    let arr = init(list)
    dispatch({
      type: GETMENUTREELIST,
      payload: arr,
    })
  }
}

/**
 * 获取菜单列表
 * @param {*} params
 * @returns
 */
export const getMenuList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/menu/', {
      params,
    })
    dispatch({
      type: GETMENULIST,
      payload: res.data.data,
    })
  }
}

/**
 * 新增菜单
 * @param {*} values
 * @returns
 */
export const addMenus = (values) => {
  return async (dispatch) => {
    await request.post('sys/menu/', values)
  }
}

/**
 * 修改菜单
 * @param {*} values
 * @returns
 */
export const editMenus = (values, id) => {
  console.log(values, id)
  return async (dispatch) => {
    await request.put(`sys/menu/${id.id}/`, values)
  }
}

/**
 * 删除菜单
 * @param {*} id
 * @returns
 */
export const delMenus = (id) => {
  return async (dispatch) => {
    await request.delete(`sys/menu/${id}/`)
  }
}

/**
 * 移动菜单
 * @param {*} values
 * @returns
 */
export const moveMenus = (values) => {
  return async (dispatch) => {
    await request.put('sys/menu/move/', values)
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/menu/check/', {
      params,
      isLoading,
    })
  }
}

import { createFromIconfontCN } from '@ant-design/icons'

export const MyIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3250468_o9nt4frvtt.js',
})

const routers = [
  {
    title: '首页',
    path: '/home',
    icon: <MyIcon type="icon-shouye-18x18" />,
  },
  {
    title: '智能撰写',
    path: '/home/intellectwrite',
    icon: <MyIcon type="icon-zhinengzhuanxie18x18" />,
  },
  {
    title: '撰写管理',
    path: '/home/writemanage',
    icon: <MyIcon type="icon-zhuanxieguanli18x18" />,
  },
  {
    title: '系统管理',
    path: '/home/sysmanage',
    icon: <MyIcon type="icon-a-xitongguanli18x18" />,
    children: [
      {
        title: '软件许可',
        path: '/home/sysmanage/license',
      },
      {
        title: '企业管理',
        path: '/home/sysmanage/compmanage',
      },
      {
        title: '角色管理',
        path: '/home/sysmanage/rolemanage',
      },
      {
        title: '用户管理',
        path: '/home/sysmanage/usermanage',
      },
      {
        title: '组织管理',
        path: '/home/sysmanage/orgmanage',
      },
      {
        title: '菜单管理',
        path: '/home/sysmanage/menumanage',
      },
      {
        title: '功能管理',
        path: '/home/sysmanage/fucmanage',
      },
      {
        title: '数据字典',
        path: '/home/sysmanage/datadictionary',
      },
      {
        title: '配置管理',
        path: '/home/sysmanage/configmanage',
      },
      {
        title: '项目管理',
        path: '/home/sysmanage/projectmanage',
      },
      {
        title: '日志管理',
        path: '/home/sysmanage/journalmanage',
      },
    ],
  },

  {
    path: '/help',
  },
  {
    path: '/home/nofunc',
  },
  {
    path: '/legal-agreement/tos.html',
  },
  {
    path: '/legal-agreement/privacy.html',
  },
  {
    path: '/legal-agreement/about.html',
  },
]

export default routers

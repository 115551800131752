// 登录页面
import { Card, Tabs, Form, Input, Button, message } from 'antd'
import styles from './index.module.scss'
import Captcha from 'react-captcha-code'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getMenus, login, registerUser } from '../../store/actions/login'
import { useHistory } from 'react-router'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useRef } from 'react'
import logo from '../../assets/logo4 (1).png'
import { getCompany } from '../../utils/cookie'
import request from '../../utils/request'
export default function LoginCas() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [initKey, setInitKey] = useState('1')
  const [form] = Form.useForm()

  useEffect(() => {
    document.title = '登录'
  }, [])
  const onFinish = async (payload) => {
    setLoading(true)
    let redirect = ''
    let url = window.location.href
    request.post('sys/login/', payload).then((res) => {
      console.log(res)
      // res.dada.dada.ticket
      if (/redirect=([^#/?]+)/.test(url)) {
        redirect = /redirect=([^#/?]+)/.exec(url)[1]
        console.log(redirect)
      }
      console.log(redirect + '?ticket=' + res.data.data.ticket)
      window.location.href = decodeURIComponent(
        redirect + '?ticket=' + res.data.data.ticket
      )
    })

    captchaRef.current?.click()
    // message.success('登录成功')
    setLoading(false)
    // }
  }
  //注册
  const register = async (payload) => {
    try {
      if (payload.confirmpassword !== payload.password) {
        message.error('两次密码不一致，请确认密码')
      } else {
        delete payload.confirmpassword
        setLoading(true)
        await dispatch(registerUser(payload))
        message.success('注册成功，请前往登录')
        changeTab('1')
        setInitKey('1')
        setLoading(false)
        form.resetFields()
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const [code, setCode] = useState('')
  const captchaRef = useRef()
  const handleChange = useCallback((e) => {
    setCode(e)
  }, [])

  const handleRef = (ref) => {
    captchaRef.current = ref.current
  }
  const handleClick = () => {
    // 主动调用click，用于更换验证码
    captchaRef.current?.click()
  }

  const changeTab = (key) => {
    console.log(key)
    setInitKey(key)
  }

  return (
    <div className={styles.root}>
      <Card className="login-container">
        <img src={logo} alt="" />
        <Tabs
          activeKey={initKey}
          defaultActiveKey={initKey}
          centered
          onChange={changeTab}
        >
          <Tabs.TabPane tab={<span>账号密码登录</span>} key="1">
            <Form
              colon={false}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={onFinish}
              validateTrigger={['onBlur', 'onChange']}
              autoComplete="off"
            >
              {/* 手机号 */}
              <Form.Item
                label="用户名"
                name="user_name"
                rules={[
                  { required: true, message: '用户名不能为空' },
                  // { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不对' },
                ]}
              >
                <Input placeholder="请输入用户名或手机号"></Input>
              </Form.Item>
              {/* 密码 */}
              <Form.Item
                label="密&nbsp;&nbsp; 码"
                name="password"
                rules={[
                  { required: true, message: '密码不能为空' },
                  // {
                  //   pattern:
                  //     /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,16}$/,
                  //   message: '请输入8-16位数字字母组合的密码',
                  // },
                ]}
              >
                <Input.Password
                  onCopy={() => {
                    return false
                  }}
                  onCut={() => {
                    return false
                  }}
                  placeholder="请输入密码"
                ></Input.Password>
              </Form.Item>
              {/* 验证码 */}
              {/* <Form.Item
                label="验证码"
                name="code"
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('验证码不能为空')
                      }
                      if (value !== code) {
                        return Promise.reject('验证码错误')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: 280 }}
                  placeholder="请输入右边的验证码"
                ></Input>
              </Form.Item>
              <div
                className="cap-code"
                style={{ position: 'fixed', top: 379, right: 154 }}
              >
                <Captcha
                  charNum={4}
                  onRef={handleRef}
                  onChange={handleChange}
                />
                <div className="cap_tips">
                  <span style={{ color: 'grey' }}>看不清，</span>
                  <a href="#" onClick={handleClick}>
                    换一个
                  </a>
                </div>
              </div> */}

              <Form.Item
                wrapperCol={{ offset: 4, span: 17 }}
                className="btn-mar"
              >
                <Button block htmlType="submit" loading={loading}>
                  登录
                </Button>
              </Form.Item>
              <div className="find-password">
                <a href="#">忘记密码？</a>
              </div>
            </Form>
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab={<span>手机登录</span>} key="2">
            手机登录
          </Tabs.TabPane> */}
          <Tabs.TabPane tab={<span>注册</span>} key="2">
            <Form
              form={form}
              colon={false}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={register}
              validateTrigger={['onBlur', 'onChange']}
              autoComplete="off"
            >
              {/* 手机号 */}
              <Form.Item
                label="用户名"
                name="username"
                rules={[
                  { required: true, message: '用户名不能为空' },
                  // { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不对' },
                ]}
              >
                <Input placeholder="请输入用户名或手机号"></Input>
              </Form.Item>
              {/* 密码 */}
              <Form.Item
                label="密&nbsp;&nbsp; 码"
                name="password"
                rules={[
                  { required: true, message: '密码不能为空' },
                  {
                    pattern:
                      /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,16}$/,
                    message: '请输入8-16位数字字母组合的密码',
                  },
                ]}
              >
                <Input.Password
                  onCopy={() => {
                    return false
                  }}
                  onCut={() => {
                    return false
                  }}
                  placeholder="请输入密码"
                ></Input.Password>
              </Form.Item>
              <Form.Item
                label="确认密码"
                name="confirmpassword"
                rules={[
                  { required: true, message: '密码不能为空' },
                  {
                    pattern:
                      /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,16}$/,
                    message: '请输入8-16位数字字母组合的密码',
                  },
                ]}
              >
                <Input.Password
                  onCopy={() => {
                    return false
                  }}
                  onCut={() => {
                    return false
                  }}
                  placeholder="请输入密码"
                ></Input.Password>
              </Form.Item>
              <Form.Item
                wrapperCol={{ offset: 4, span: 17 }}
                className="btn-mar"
              >
                <Button block htmlType="submit" loading={loading}>
                  注册
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab={<span>扫码登录</span>} key="3">
            扫码登录
          </Tabs.TabPane> */}
        </Tabs>
      </Card>
    </div>
  )
}

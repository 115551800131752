import "./index.scss";
import { Button, Drawer, Empty, Spin, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import LoadingSvg from "./loading.svg";
import emptyImg from "./暂无icon@2x.png";
import { ReactSVG } from "react-svg";
import { useGetState } from "ahooks";
import InfiniteScroll from "react-infinite-scroll-component";

const UpdateLogsDrawer = (props) => {
  const { getUpdateLogList, validationFunc } = props;

  const [updatelogList, setUpdatelogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [total, setTotal] = useState(0);

  const getUpdateLogListFunc = (data, notLoading) => {
    if (getUpdateLogList) {
      if (!notLoading) {
        setLoading(true);
      }
      getUpdateLogList(getPageNumber(), 20).then((res) => {
        console.log(res);
        if (validationFunc(res)) {
          setUpdatelogList(data?.concat(res.data.data));
          setTotal(res.data.total);
        } else {
          setUpdatelogList([]);
          setTotal(0);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (props.visible) {
      getUpdateLogListFunc([]);
    } else {
      setPageNumber(1);
      setTotal(0);
      setUpdatelogList([]);
    }
  }, [props.visible]);

  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    await setPageNumber(getPageNumber() + 1);
    getUpdateLogListFunc(updatelogList || [], true);
    // setLoading(true);
  };

  return (
    <Drawer
      className="updateLogsDrawer"
      width="600px"
      closable={false}
      title="更新日志"
      placement="right"
      push={false}
      onClose={props.onClose}
      visible={props.visible}
      zIndex={1031}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              props.onClose();
            }}
            type="primary"
          >
            关闭
          </Button>
        </div>
      }
    >
      <div
        id="infiniteScrollBox"
        style={{ height: "100%", overflow: "auto", padding: "20px" }}
      >
        {loading ? (
          <Spin
            className="mySpinLoading"
            tip={props.tip}
            indicator={
              <ReactSVG
                src={LoadingSvg}
                className="anticon-spin anticon myLoadingIcon"
              />
            }
          ></Spin>
        ) : updatelogList?.length > 0 ? (
          <>
            <div className="msg">{updatelogList[0]?.sys_name}</div>

            <div
              className="drawer_body"
              style={{
                height: "calc(100% - 80px)",
                marginTop: 20,
                width: "100%",
              }}
            >
              <InfiniteScroll
                dataLength={updatelogList.length}
                next={loadMoreData}
                loader={<Divider className="loadingBox">Loading</Divider>}
                hasMore={updatelogList.length < total}
                //
                scrollableTarget={"infiniteScrollBox"}
                // endMessage={<Divider className="noDataDivider"></Divider>}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    // overflow: "auto",
                  }}
                >
                  {updatelogList.map((item) => (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ color: "#006AB2" }}>
                          {item.update_date}
                        </div>
                        <div style={{ color: "#0068B2" }}>{item.ver_no}</div>
                      </div>
                      <div
                        style={{ color: "#535353", margin: "16px 0px" }}
                        dangerouslySetInnerHTML={{
                          __html: item.update_log || "-",
                        }}
                      ></div>
                      <hr style={{ border: "1px dashed #D8D8D8" }} />
                      <br />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <div className="emptyBox">
            <Empty image={emptyImg} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default UpdateLogsDrawer;

import { useEffect, useState } from 'react'
import { Button, Result, Form, Input, message } from 'antd'
import Api from '../../api/index'

export default function Register(props) {
  const { closeWin, userInfo = {}, loginId } = props
  const [isConfirm, setIsConfirm] = useState(false)
  const [form] = Form.useForm()
  const [countdownSecond, setCountdownSecond] = useState(0)

  const [registerStatus, setRegisterStatus] = useState(1)

  const setSMSCountdownTimer = (time) => {
    //如果之前有定时器  清掉
    if (window.SMSCountdownTimer) {
      clearTimeout(window.SMSCountdownTimer)
      window.SMSCountdownTimer = null
    }
    //就时间缓冲
    const oldSMSCountdownSecond =
      window.localStorage.getItem('SMSCountdownSecond')
    //存入新的时间 缓存
    const diff = time - oldSMSCountdownSecond
    //如果超过60s
    if (diff > 60 * 1000) {
      return
    } else {
      //未超过60s
      let intervalTime = 60 * 1000 - diff
      intervalTime = parseInt(intervalTime / 1000)
      if (intervalTime <= 0) {
        intervalTime = 0
      }
      //修改
      if (setCountdownSecond) {
        setCountdownSecond(intervalTime)
      }
      //设置定时器
      setTimeout(() => {
        setSMSCountdownTimer(new Date().getTime())
      }, 1000)
    }
  }

  //发送短信
  const sendingSMS = () => {
    form.validateFields(['phoneNumber']).then((res) => {
      const { phoneNumber } = res
      //todo 调取发送验证码的接口
      Api.getVerifyCode({ phone: phoneNumber, type: 3 }).then((res) => {
        if (res.data?.code === 0) {
          window.localStorage.setItem(
            'SMSCountdownSecond',
            new Date().getTime()
          )
          setSMSCountdownTimer(new Date().getTime())
        } else {
          message.error(res.data.msg)
        }
      })
    })
  }

  //用户绑定企业
  const bindEnterprise = (enterpriseName) => {
    Api.bindEnterprise(enterpriseName).then((res) => {
      console.log('🚀 ~ file: register.jsx:57 ~ Api.用户创建企业 ~ res:', res)
      if (res.data.code === 0) {
        Api.switchEnterprise(loginId, res.data.data?.company_id).then(
          (res2) => {
            console.log(
              '🚀 ~ file: register.jsx:63 ~ Api.用户切换企业 ~ res2:',
              res2
            )
            if (res2.data.code === 0) {
              setRegisterStatus(1)
              setTimeout(() => {
                setIsConfirm(true)
                closeWin()
              }, 100)
            }
          }
        )
      } else if (res.data.code === 10206) {
        setRegisterStatus(3)
        setTimeout(() => {
          setIsConfirm(true)
        }, 100)
      } else {
        message.error(res.data.msg)
      }
    })
  }

  //创建
  const creatFunc = () => {
    form.validateFields().then((res) => {
      console.log(
        '🚀 ~ file: register.jsx:67 ~ form.validateFields ~ res:',
        res
      )
      const { enterpriseName, phoneNumber, verificationCode } = res
      //注册过企业确认
      if (userInfo?.co_id) {
        setRegisterStatus(2)
        setTimeout(() => {
          setIsConfirm(true)
        }, 100)
      } else {
        //已经绑定过手机号
        if (userInfo?.mobile_no) {
          bindEnterprise(enterpriseName)
        } else {
          //未绑定手机号
          Api.improveUserInfo({
            phone: phoneNumber,
            verify_code: verificationCode,
          }).then((res) => {
            if (res.data.code === 0) {
              bindEnterprise(enterpriseName)
            } else {
              message.error(res.data.msg)
            }
          })
        }
      }
    })
  }

  useEffect(() => {
    if (userInfo?.mobile_no) {
      const str = `${userInfo?.mobile_no?.slice(
        0,
        3
      )} **** ${userInfo?.mobile_no?.slice(7, 11)}`
      form.setFieldsValue({
        phoneNumber: str,
      })
    }
  }, [userInfo])

  return (
    <div
      className="registerContentBox"
      style={{ height: isConfirm ? 'auto' : '100%' }}
    >
      {!isConfirm ? (
        <div className="formBox">
          <Form colon={false} layout="vertical" autoComplete="off" form={form}>
            <Form.Item
              label="企业或组织名称"
              name="enterpriseName"
              rules={[{ required: true, message: '企业或组织名称不能为空' }]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder="请输入企业或组织名称"
              ></Input>
            </Form.Item>

            {userInfo?.mobile_no ? (
              <Form.Item
                label="手机号"
                name="phoneNumber"
                rules={[{ required: true, message: '企业或组织名称不能为空' }]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="请输入手机号"
                  disabled={true}
                ></Input>
              </Form.Item>
            ) : (
              <Form.Item
                label="手机号"
                name="phoneNumber"
                rules={[
                  { required: true, message: '手机号不能为空' },
                  { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不对' },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="请输入手机号"
                ></Input>
              </Form.Item>
            )}

            {!userInfo?.mobile_no && (
              <Form.Item
                label="验证码"
                name="verificationCode"
                rules={[
                  { required: true, message: '验证码不能为空' },
                  // { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不对' },
                ]}
              >
                <div style={{ display: 'flex' }}>
                  <Input
                    style={{ width: 'calc(100%- 84px)', marginRight: '8px' }}
                    placeholder="请输入验证码"
                  ></Input>
                  <Button
                    className="verificationCodeBtn"
                    type="primary"
                    onClick={sendingSMS}
                    disabled={countdownSecond}
                  >
                    {countdownSecond
                      ? countdownSecond + 's后重试'
                      : '获取验证码'}
                  </Button>
                </div>
              </Form.Item>
            )}
          </Form>
          <Button className="creatBtn" type="primary" onClick={creatFunc}>
            创建
          </Button>
        </div>
      ) : (
        <div className="registerContent">
          <Result
            status="success"
            title={
              registerStatus === 1
                ? '注册成功'
                : registerStatus === 2
                ? '您已经注册过企业/组织'
                : '同一个账号最多可以注册5个企业或组织'
            }
            subTitle={registerStatus === 2 ? '是否需要继续注册？' : null}
          />

          {registerStatus === 1 && (
            <Button type="primary" className="loginBtn" onClick={closeWin}>
              完成
            </Button>
          )}
          {registerStatus === 2 && (
            <Button
              type="primary"
              className="loginBtn"
              onClick={() => {
                bindEnterprise(form.getFieldValue('enterpriseName'))
              }}
            >
              需要
            </Button>
          )}
          {registerStatus === 2 && (
            <Button className="closeBtn" onClick={closeWin}>
              不需要
            </Button>
          )}
          {registerStatus === 3 && (
            <Button type="primary" className="loginBtn" onClick={closeWin}>
              好的
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

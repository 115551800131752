import request from '../../utils/request'
import { GETFUCLIST, GETFUCTREELIST } from '../constants'

/**
 * 获取树形数据列表
 * @returns
 */
export const getFucTreeList = (params, disable, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/func/tree/', { params, isLoading })
    // console.log(res.data.data)
    const init = (arr) => {
      arr = arr.map((item) => {
        if (item.children) {
          item.children = init(item.children)
        }
        item.title = item.func_name || '全部'
        item.value = item.func_code || '全部'
        item.key = item.func_id || '全部'
        item.disabled = disable && item.is_preset ? true : false
        return item
      })
      return arr
    }
    let list = [res.data.data]
    let arr = init(list)
    dispatch({
      type: GETFUCTREELIST,
      payload: arr,
    })
  }
}
/**
 * 获取功能列表
 * @param {*} params
 * @returns
 */
export const getFucList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/func/', {
      params,
    })
    dispatch({
      type: GETFUCLIST,
      payload: res.data.data,
    })
  }
}

/**
 * 添加功能
 * @param {*} values
 * @returns
 */
export const addFucs = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/func/', values)
    console.log(res)
  }
}

/**
 * 修改功能
 * @param {*} values
 * @returns
 */
export const editFucs = (values) => {
  return async (dispatch) => {
    await request.put(`sys/func/${values.id}/`, values)
  }
}

/**
 * 删除功能
 * @param {*} id
 * @returns
 */
export const delFucs = (id) => {
  return async (dispatch) => {
    await request.delete(`sys/func/${id}/`)
  }
}

/**
 * 移动功能
 * @param {*} values
 * @returns
 */
export const moveFucs = (values) => {
  return async (dispatch) => {
    await request.put('sys/func/move/', values)
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/func/check/', {
      params,
      isLoading,
    })
  }
}

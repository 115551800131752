import React, { useEffect, useState } from 'react'
import Api from '../../../api/index'
import { Form, Input, Button, message } from 'antd'
import useUrlState from '@ahooksjs/use-url-state'
import styles from './index.module.scss'
import gouxuan from '../../images/gouxuan.png'
import { useDispatch } from 'react-redux'
import { joinComp } from '../../../store/actions/permission'
import { getToken, setRedirectUrl, setToken } from '../../../utils/cookie'
import { useHistory, useLocation } from 'react-router-dom'
import { closeWin } from '../../../utils/closewin'
import { isMobile } from 'react-device-detect'
export default function ApplMemberPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [state, setState] = useUrlState({})
  const [loading, setLoading] = useState(true)
  const [expired, setExpired] = useState(false)
  const [coName, setCoName] = useState(null)

  const getUserInfo = (code) => {
    console.log(code)
    Api.getTokenByCode(code).then((res) => {
      console.log(res)
      if (res.data.code === 0) {
        if (res.data.data?.unauthorization) {
          //未授权
        } else if (res.data.data?.expired) {
          //过期
          setExpired(true)
        } else {
          setToken(res.data.data?.token)
        }
      }
      setTimeout(() => {
        setLoading(false)
      }, 200)
    })
  }
  useEffect(() => {
    document.title = 'iWudao'
    const token = getToken()
    //获取用户信息
    // const myState = { form:  location?.pathname + location?.search}

    const str = window.location.pathname?.slice(13) || null
    setCoName(decodeURIComponent(str))
    if (state.code) {
      // setCoName(encodeURI(str))
      getUserInfo(state.code)
    } else {
      if (!token) {
        const to = location?.pathname + location?.search;
        window.localStorage.setItem('to',to);
        setRedirectUrl(window.location.href);
        history.push(`/loginHtml`)
        // history.push('/saaslogin', to)
      }
      // setCoName(encodeURI(state.co_name))
      getUserInfo(state.code)
      setExpired(true)
      setLoading(false)
    }
  }, [])

  const onFinish = async (values) => {
    values.invite_id = encodeURI(state.sid)
    if (!values.full_name) {
      return message.error('姓名不能为空')
    }
    try {
      const res = await dispatch(joinComp(values))
      if (res.data.code === 0) {
        message.success('加入企业成功')
        document.getElementById('conId').style.display = 'none'
        document.getElementById('sucesId').style.display = 'block'
      } else {
        return message.error(res.data.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.root}>
      <div className="content_div" id="conId">
        <h2>{coName}</h2>
        <p>正在使用吾道科技产品，邀请您尽快加入</p>
        <div className="content_form">
          <Form colon={false} onFinish={onFinish} layout="vertical">
            <Form.Item label="姓名" name="full_name">
              <Input placeholder="请输入名字"></Input>
            </Form.Item>
            <Form.Item label="申请理由" name="reason">
              <Input.TextArea placeholder="请输入申请理由"></Input.TextArea>
            </Form.Item>
            <Form.Item>
              <Button className="submit_btn" block htmlType="submit">
                提交申请
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="content_div" style={{ display: 'none' }} id="sucesId">
        <img
          style={{ paddingTop: '20%', paddingBottom: 24 }}
          src={gouxuan}
          alt=""
        />
        <h3>加入企业申请已提交</h3>
        <p style={{ padding: '16px 0px 40px 0px' }}>
          待管理员审核后，您就可以用微信扫码或手机号登录吾道科技企业版体验我们的产品服务
        </p>
        <Button
          onClick={() => {
            console.log(isMobile)
            if (isMobile) {
              closeWin()
            } else {
              history.push('/home')
            }
          }}
          style={{ width: 300 }}
          className="submit_btn"
        >
          好的
        </Button>
      </div>
    </div>
  )
}
